import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { ScrollToTopOnMount } from "../helpers/hooks";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import Form from "../components/Contact/Form";
import kontaktImg from "../assets/kontakt.jpg";

const Contact = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Box bgColor="rGray.100" pb={20}>
        <Box maxW="7xl" w="100%" mx="auto">
          <HeroTitle title="Kontakt" imgSrc={kontaktImg} bgPos="bottom" />
        </Box>
      </Box>
      <Box bgColor="rBlue.700">
        <Flex maxW="7xl" w="100%" mx="auto" flexWrap="wrap">
          <Box
            w={{ base: "100%", lg: 1 / 2 }}
            borderRight={{ base: 0, lg: "4px solid #F8F9FA" }}
            px={{ base: 6, lg: 0 }}
          >
            <Heading color="white" py={6}>
              Kontaktirajte nas
            </Heading>
            <Form />
          </Box>
          <Box
            h="6px"
            w="100%"
            d={{ base: "block", lg: "none" }}
            bgColor="#F8F9FA"
            mt={12}
            mb={4}
          ></Box>
          <Box
            w={{ base: "100%", lg: 1 / 2 }}
            borderLeft={{ base: 0, lg: "4px solid #F8F9FA" }}
            pb={24}
            px={{ base: 6, lg: 0 }}
          >
            <Heading color="white" py={6} textAlign="center" fontSize="4xl">
              ROTARY KLUB
            </Heading>
            <Box color="white" maxW="md" mx="auto" mt={4}>
              <Text fontSize="xl">Hotel Central</Text>
              <Text fontSize="xl">Vatroslava Lisinskog 2</Text>
              <Text fontSize="xl">43 000 Bjelovar</Text>
            </Box>
            <Box
              h="5px"
              bgColor="rBlue.300"
              maxW="md"
              w="100%"
              mx="auto"
              my={14}
            />
            <Heading color="white" pb={6} textAlign="center" fontSize="4xl">
              PODACI
            </Heading>
            <Box color="white" maxW="md" mx="auto" mt={4}>
              <Text fontSize="xl">OIB: 79124655834</Text>
              <Text fontSize="xl">Predsjednik kluba: Boris Budinjaš</Text>
              <Text
                as={Link}
                color="rBlue.300"
                _hover={{ textDecor: "none" }}
                fontSize="xl"
                href="mailto:predsjednik@rotary-klub-bjelovar.hr"
              >
                predsjednik@rotary-klub-bjelovar.hr
              </Text>
            </Box>
            <Box color="white" maxW="md" mx="auto" mt={4}>
              <Text fontSize="xl">Tajnica kluba: Andrea Dokmanović</Text>
              <Text
                as={Link}
                color="rBlue.300"
                _hover={{ textDecor: "none" }}
                fontSize="xl"
                href="mailto:tajnik@rotary-klub-bjelovar.hr"
              >
                tajnik@rotary-klub-bjelovar.hr
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box bgColor="rGray.100" h="100px" w="100%"></Box>
    </>
  );
};

export default Contact;
