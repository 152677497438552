import { Box, Flex, Link, IconButton } from "@chakra-ui/react";
import { ReactComponent as FbIcon } from "../../assets/fb_icon.svg";

const Footer = () => {
  return (
    <Box bgColor="rBlue.700" pt={4}>
      <Box maxW="7xl" w="100%" mx="auto" h={{ base: "auto", lg: "100px" }}>
        <Flex mx="auto" maxW="2xl" w="100%" mt={4} flexWrap="wrap">
          <Box
            w={{ base: "100%", lg: 1 / 3 }}
            my={{ base: 2, lg: 0 }}
            textAlign="center"
          >
            <Link
              href="https://www.rotary.org"
              target="_blank"
              rel="noopener noreferrer"
              color="white"
            >
              Rotary.org
            </Link>
          </Box>
          <Box
            w={{ base: "100%", lg: 1 / 3 }}
            my={{ base: 2, lg: 0 }}
            textAlign="center"
          >
            <Link
              href="https://www.rotary.org/en/about-rotary/rotary-foundation"
              target="_blank"
              rel="noopener noreferrer"
              color="white"
            >
              The Rotary Foundation
            </Link>
          </Box>
          <Box
            w={{ base: "100%", lg: 1 / 3 }}
            my={{ base: 2, lg: 0 }}
            textAlign="center"
          >
            <Link
              href="https://rotary.hr/"
              target="_blank"
              rel="noopener noreferrer"
              color="white"
            >
              Rotary Distrikt 1913 Croatia
            </Link>
          </Box>
        </Flex>
        <Flex justifyContent="center" mt={4} mb={{ base: 4, lg: 0 }}>
          <IconButton
            as={Link}
            bgColor="transparent"
            href="https://www.facebook.com/RotaryKlubBjelovar/"
            target="_blank"
            rel="noopener noreferrer"
            w="40px"
            icon={<FbIcon />}
            _focus={{ outline: "none" }}
            _active={{ outline: "none" }}
            _hover={{ bgColor: "transparent" }}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
