import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { handleGetRequest } from "../helpers/requests";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import projekti from "../assets/projekti.jpg";
import ProjectCard from "../components/Projects/ProjectCard";

const Projects = () => {
  const { isLoading, data } = useQuery("projekti", () =>
    handleGetRequest(
      "https://rotobj.parpar.hr/wp-json/wp/v2/projekt?_embed&_fields=author,date,id,excerpt,title,link,content,embedded,_links&per_page=100"
    ).then((res) => {
      const data = res.data.map((item) => {
        return {
          id: item.id,
          title: item.title.rendered,
          excerpt: item.excerpt && item.excerpt.rendered,
          date: item.date,
          featured_img_full: item["_embedded"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes.full
                .source_url
            : "",
          featured_img_tumbnail: item["_embedded"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes
                .medium.source_url
            : "",
        };
      });
      return data;
    })
  );
  return (
    <Box w="100%" bgColor="rGray.100" pb={1}>
      <Box maxW="7xl" w="100%" mx="auto">
        <HeroTitle imgSrc={projekti} title="Projekti" bgPos="bottom" />
        <Flex flexWrap="wrap" mt={{ base: 10, lg: 20 }}>
          {!isLoading ? (
            data.map((item) => {
              return <ProjectCard data={item} key={item.id} />;
            })
          ) : (
            <>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
              <Box p={4} w={{ base: "100%", lg: 1 / 2 }} h="100%">
                <Skeleton h="150px" w="100%" borderRadius="lg" />
              </Box>
            </>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Projects;
