import { Box, Flex } from "@chakra-ui/react";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import { ScrollToTopOnMount } from "../helpers/hooks";
import PersonCard from "../components/Administration/PersonCard";
import uprava from "../assets/uprava.jpg";

const people = [
  { name: "Boris Budinjaš", position: "Predsjednik" },
  { name: "Daniel Lekić", position: "PREDSJEDNIK ELECT" },
  { name: "Boris Kirin", position: "POTPREDSJEDNIK 1" },
  { name: "Željko Maletić", position: "POTPREDSJEDNIK 2" },
  { name: "Zvonimir Žarec", position: "RIZNIČAR" },
  { name: "Andrea Dokmanović", position: "TAJNICA" },
  { name: "Marijan Trnski", position: "PRIJAŠNJI PREDSJEDNIK" },
  { name: "Davorin Frčo", position: "STOLORAVNATELJ" },
  { name: "", position: "VODITELJ ODBORA S JAVNOŠĆU" },
  { name: "Mislav Klobučić", position: "VODITELJ ODBORA ZA PREDAVANJA" },
  {
    name: "Martina Dujmović",
    position: "VODITELJICA ODBORA ZA PRAVNA PITANJA",
  },
  { name: "Zlatko Gregurić", position: "VODITELJ ODBORA ZA ČLANSTVO" },
  {
    name: "Andrea Dokmanović",
    position: "VODITELJICA ODBORA ZA ROTARY FOUNDATION",
  },
  { name: "Zrinko Arvay", position: "VODITELJ ODBORA ZA RAZMJENU MLADIH" },
];

const Administration = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Box w="100%" bgColor="rGray.100" pb={6}>
        <Box maxW="7xl" mx="auto">
          <HeroTitle title="Uprava" imgSrc={uprava} />
          <Flex
            mt={{ base: 10, lg: 20 }}
            pb={20}
            px={{ base: 0, xl: 0 }}
            flexWrap="wrap"
          >
            {people.map((item) => {
              return <PersonCard data={item} key={item.position} />;
            })}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default Administration;
