import { Box, Flex, Heading } from "@chakra-ui/react";

function capitalizeFirstLetter(string) {
  const lowerString = string.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
}

const PersonCard = ({ data }) => {
  return (
    <Box p={4} w={{ base: "100%", lg: 1 / 3 }}>
      <Flex
        h="100%"
        boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
        p={6}
        borderRadius="12px"
        flexWrap="wrap"
        alignContent="center"
        bgColor="white"
      >
        <Box w="100%">
          <Heading fontSize="sm" color="rGreen.500" >
            {capitalizeFirstLetter(data.position)}
          </Heading>
        </Box>
        <Box w="100%">
          <Heading color="rGray.600" h={data.name ? "" : "43px"}>
            {data.name}
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};
export default PersonCard;
