import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import logo from "../../assets/rc_bj_logo.svg";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";
import { useForm } from "react-hook-form";

const Header = () => {
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  function submit(values, e) {
    e.target.reset();
    history.push(`/pretraga/${values.pretraga}`);
  }
  return (
    <Box w="100%" as="header">
      <Box w="100%" borderBottom="0.2px solid rgba(94, 113, 125, 0.5)">
        <Box maxW="7xl" mx="auto">
          <Flex w="100%" py={{ base: 4, lg: 0 }}>
            <Box w={{ base: 6 / 12, lg: 7 / 12 }} mx={2}>
              <Link to="/">
                <Image w={{ base: "", lg: 1 / 3 }} py={4} src={logo} />
              </Link>
            </Box>
            <Box w={{ base: 6 / 12, lg: 3 / 12 }} mx={2} alignSelf="center">
              <Box alignSelf="center">
                <Text
                  fontFamily="Open Sans"
                  fontStyle="italic"
                  color="rBlue.700"
                  fontSize={{ base: "sm", lg: "0.95rem" }}
                  textAlign="end"
                >
                  Ideas have unhinged the gates of empires.
                </Text>
                <Text
                  textAlign="end"
                  color="rBlue.700"
                  fontSize={{ base: "sm", lg: "0.9rem" }}
                  fontFamily="Open Sans"
                >
                  Paul Harris
                </Text>
              </Box>
            </Box>
            <Box
              w={2 / 12}
              mx={2}
              alignSelf="center"
              d={{ base: "none", lg: "block" }}
            >
              <Flex
                justifyContent="flex-end"
                as="form"
                onSubmit={handleSubmit(submit)}
              >
                <FormControl isInvalid={errors.pretraga && true}>
                  <InputGroup size="sm">
                    <Input
                      name="pretraga"
                      placeholder="Pretraži..."
                      borderTopLeftRadius="6px"
                      borderBottomLeftRadius="6px"
                      ref={register({
                        required: "Polje ne smije biti prazno",
                      })}
                    />
                    <InputRightElement width="2.5rem">
                      <IconButton
                        type="submit"
                        borderTopLeftRadius="0"
                        borderBottomLeftRadius="0"
                        h="100%"
                        color="white"
                        w="100%"
                        colorScheme="rBlue"
                        size="sm"
                        icon={<SearchIcon />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.pretraga && errors.pretraga.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        w="100%"
        borderBottom="0.2px solid rgba(94, 113, 125, 0.5)"
        bgColor="rGray.100"
      >
        <Menu />
        <MobileMenu />
        <Box maxW="7xl" mx="auto"></Box>
      </Box>
    </Box>
  );
};

export default Header;
