import { useState, useRef } from "react";
import { Box, Flex, IconButton, Skeleton, Text } from "@chakra-ui/react";
import { ScrollToTopOnMount } from "../helpers/hooks";
import { useQuery } from "react-query";
import { handleGetRequest } from "../helpers/requests";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import NewsCard from "../components/Main/NewsCard";
import { ReactComponent as ArrowRight } from "../assets/right_arrow.svg";
import { ReactComponent as ArrowLeft } from "../assets/left_arrow.svg";
import vijesti from "../assets/vijesti.jpg";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const News = () => {
  const topRef = useRef();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const executeScroll = () => scrollToRef(topRef);

  const { isLoading,  data } = useQuery(["allNews", page], () =>
    handleGetRequest(
      `https://rotobj.parpar.hr/wp-json/wp/v2/vijest?_embed&_fields=author,date,id,excerpt,title,link,content,embedded,_links&per_page=12&page=${page}`
    ).then((res) => {
      setTotalPages(Number(res.headers["x-wp-totalpages"]));

      const data = res.data.map((item) => {
        const excerpt =
          item.excerpt.rendered.length > 300
            ? item.excerpt.rendered.slice(0, -151)
            : item.excerpt.rendered;
        return {
          id: item.id,
          title: item.title.rendered,
          excerpt: excerpt,
          date: item.date,
          featured_img_full: item["_embedded"]["wp:featuredmedia"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes.full
                .source_url
            : "",
          featured_img_tumbnail: item["_embedded"]["wp:featuredmedia"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes
                .medium.source_url
            : "",
        };
      });
      return data;
    })
  );
  return (
    <>
      <ScrollToTopOnMount />
      <Box bgColor="rGray.100">
        <Box maxW="7xl" w="100%" mx="auto" ref={topRef}>
          <HeroTitle title="Vijesti" imgSrc={vijesti} />
          <Flex flexWrap="wrap" mt={{ base: 10, lg: 20 }} pb={12}>
            {!isLoading ? (
              data.map((item) => {
                return <NewsCard data={item} key={item.id} />;
              })
            ) : (
              <>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
                <Box p={6} w={{ base: "100%", lg: 1 / 3 }} h="256px">
                  <Skeleton borderRadius="lg" w="100%" h="100%" />
                </Box>
              </>
            )}
          </Flex>
          {totalPages > 1 ? (
            <Flex
              mx="auto"
              w="100%"
              maxW="xs"
              justifyContent="space-around"
              pb={10}
            >
              <IconButton
                disabled={
                  page > 1 && page <= totalPages && totalPages ? false : true
                }
                onClick={() => {
                  executeScroll();
                  setPage((page) => page - 1);
                }}
                alignSelf="center"
                bgColor="transparent"
                w="40px"
                _hover={{
                  bgColor: "transparent",
                }}
                _active={{
                  bgColor: "transparent",
                }}
                _focus={{
                  outline: "none",
                }}
                icon={<ArrowLeft />}
              />

              <Box alignSelf="center">
                <Text>
                  {page} od {totalPages}
                </Text>
              </Box>
              <IconButton
                disabled={page < totalPages && page >= 1 ? false : true}
                onClick={() => {
                  executeScroll();
                  setPage((page) => page + 1);
                }}
                alignSelf="center"
                bgColor="transparent"
                w="40px"
                _hover={{
                  bgColor: "transparent",
                }}
                _active={{
                  bgColor: "transparent",
                }}
                _focus={{
                  outline: "none",
                }}
                icon={<ArrowRight />}
              />
            </Flex>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default News;
