import { useParams } from "react-router-dom";
import { ScrollToTopOnMount } from "../helpers/hooks";
import { useQuery } from "react-query";
import { Flex, Box } from "@chakra-ui/react";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import { handleGetRequest } from "../helpers/requests";
import { PostContent } from "../helpers/components";
import { decodeHtml } from "../helpers/functions";

const GalleryPost = () => {
  let { id } = useParams();
  const index = id.lastIndexOf("-");
  const postID = Number(id.substr(index + 1, id.length));
  const { data } = useQuery(`singleGallery${id}`, () =>
    handleGetRequest(
      `https://rotobj.parpar.hr/wp-json/wp/v2/galerija/${postID}?_embed=1`
    ).then((res) => {
      return res.data;
    })
  );
  const postData = data && data.content.rendered;
  return (
    <>
      <ScrollToTopOnMount />
      {data ? (
        <Box bgColor="rGray.100" pb={1}>
          <Box maxW="7xl" w="100%" mx="auto">
            <HeroTitle
              hasBreadcrumbs
              title={decodeHtml(data.title.rendered)}
              imgSrc={
                data["_embedded"] &&
                data["_embedded"]["wp:featuredmedia"][0].media_details.sizes
                  .large.source_url
              }
            />
            <Flex w="100%" mx="auto" my={8} px={{ base: 2, lg: 0 }}>
              <div className="post">
                <PostContent content={postData} id={postID}></PostContent>
              </div>
            </Flex>
          </Box>
        </Box>
      ) : (
        <>
          <Box height="100vh"> </Box>
        </>
      )}
    </>
  );
};
export default GalleryPost;
