import { Box, Image } from "@chakra-ui/react";

const WICard = ({ title, imgSrc }) => {
  return (
    <Box w={{ base: "100%", lg: 1 / 2 }} px={{ base: 6 }} my={4}>
      <Box
        borderRadius="lg"
        bgColor="rBlue.300"
        boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
      >
        <Image
          src={imgSrc.replace("http:", "https:")}
          borderTopRightRadius="lg"
          borderTopLeftRadius="lg"
          alt={`card image`}
          objectFit="cover"
          w="100%"
          h="200px"
        />

        <Box p="4" borderBottomRightRadius="lg" borderBottomLeftRadius="lg">
          <Box
            mt="1"
            as="h4"
            lineHeight="tight"
            fontFamily="Open Sans"
            textAlign="center"
            color="white"
          >
            {title}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WICard;
