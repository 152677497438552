import { Link } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";
import { decodeHtml } from "../../helpers/functions";

const GalleryCard = ({ data }) => {
  return (
    <Box w={{ base: "100%", lg: 1 / 3 }} px={{ base: 6 }} my={4}>
      <Box as={Link} to={`/galerija/${data.id}`}>
        <Box
          h="100%"
          bgColor="white"
          borderRadius="lg"
          boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
        >
          <Image
            src={data.firstImg.replace("http:", "https:")}
            borderTopRightRadius="lg"
            borderTopLeftRadius="lg"
            alt={`card image`}
            objectFit="cover"
            w="100%"
            h="200px"
          />

          <Box p="4" borderBottomRightRadius="lg" borderBottomLeftRadius="lg">
            <Box
              mt="1"
              as="h4"
              lineHeight="tight"
              fontFamily="Open Sans"
              textAlign="center"
              color="gray.600"
            >
              {decodeHtml(data.title)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GalleryCard;
