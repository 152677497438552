import { Box, Flex, Image, Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { handleGetRequest } from "../../helpers/requests";
import hero from "../../assets/homepage_hero.png";
import rotary_inspiration from "../../assets/rotary_inspiration.svg";
import NewsCard from "./NewsCard";

const MainHeading = () => {
  const { isLoading,  data } = useQuery("news", () =>
    handleGetRequest(
      "https://rotobj.parpar.hr/wp-json/wp/v2/vijest?_embed&_fields=author,date,id,excerpt,title,link,content,embedded,_links&per_page=2"
    ).then((res) => {
      const data = res.data.map((item) => {
        const excerpt =
          item.excerpt.rendered.length > 300
            ? item.excerpt.rendered.slice(0, -151)
            : item.excerpt.rendered;
        return {
          id: item.id,
          title: item.title.rendered,
          excerpt: excerpt,
          date: item.date,
          featured_img_full: item["_embedded"]["wp:featuredmedia"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes.full
                .source_url
            : "",
          featured_img_tumbnail: item["_embedded"]["wp:featuredmedia"]
            ? item["_embedded"]["wp:featuredmedia"][0].media_details.sizes
                .medium.source_url
            : "",
        };
      });
      return data;
    })
  );
  return (
    <Box maxW="7xl" mx="auto">
      <Image src={hero} />
      <Flex flexWrap={{ base: "wrap", md: "nowrap" }} flexDir="column">
        <Flex mx="auto">
          <Image
            my={8}
            mt={14}
            mx="auto"
            w={{ base: "100%", lg: 3 / 4 }}
            px={{ base: 4, lg: 0 }}
            src={rotary_inspiration}
          />

          {/*  <Heading
            d="flex"
            pos="relative"
            textAlign="center"
            my={8}
            mt={14}
            color="rBlue.700"
            fontFamily="Open Sans"
            fontSize={{ base: "4xl", md: "5xl" }}
            _after={{
              pos: "absolute",
              content: "''",
              h: "3px",
              bottom: "-6px",
              left: "0",
              width: { base: "100%", md: "57%" },
              bgColor: "rBlue.300",
            }}
          >
            Rotary klub Bjelovar
          </Heading> */}
        </Flex>
      </Flex>
      {!isLoading ? (
        <Flex
          maxW="6xl"
          mx="auto"
          mt={12}
          mb={12}
          flexWrap={{ base: "wrap", md: "nowrap" }}
          justifyContent="space-between"
        >
          {!isLoading &&
            data.map((item) => {
              return <NewsCard key={item.id} data={item} />;
            })}
        </Flex>
      ) : (
        <Flex
          maxW="6xl"
          mx="auto"
          mt={12}
          mb={12}
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <Skeleton
            borderRadius="12px"
            w={{ base: "100%", md: 1 / 2 }}
            p={6}
            mx={6}
            h="342px"
          />
          <Skeleton
            borderRadius="12px"
            w={{ base: "100%", md: 1 / 2 }}
            p={6}
            mx={6}
            h="342px"
          />
        </Flex>
      )}
    </Box>
  );
};

export default MainHeading;
