import { useQuery } from "react-query";
import { handleGetRequest } from "./requests";
import parse, { domToReact } from "html-react-parser";
import {
  Text,
  Image,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  IconButton,
  Heading,
  forwardRef,
} from "@chakra-ui/react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion";
import { wrap } from "popmotion";
import styled from "@emotion/styled";
import "./style.scss";

const MotionIconButton = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <IconButton ref={ref} {...chakraProps} />;
  })
);

const OpenGallery = ({ images, id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MotionIconButton
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        icon={<SearchIcon />}
        bgColor="transparent"
        d="flex"
        mt={1}
        fontSize="1.5rem"
        onClick={onOpen}
        mx="auto"
        _focus={{ boxShadow: "none" }}
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
      />
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={4} h="auto">
          <Box userSelect="none" className="imageContainer">
            {images && <Gallery images={images} />}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PostContent = (props) => {
  const { data } = useQuery(`categorieIdsGallery${props.id}`, () =>
    handleGetRequest(
      `https://www.rotobj.parpar.hr/wp-json/galleries_plugin/v1/post/${props.id}`
    ).then((res) => {
      if (typeof res.data === typeof "" && res.data.trim() === "") {
        return [];
      } else {
        return res.data.map((item) => {
          return item.src;
        });
      }
    })
  );
  const getImages = (id) => {
    return (
      <>
        <Box
          userSelect="none"
          className="imageContainer"
          h={{ base: "250px", md: "100%" }}
        >
          {data && <Gallery images={data[id - 1]} />}
        </Box>
        {data && <OpenGallery images={data[id - 1]} id={id} />}
      </>
    );
  };
  const options = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;

      if (name === "p") {
        return (
          <Text
            fontSize={
              props.excerptFont ? props.excerptFont : { base: 14, sm: 18 }
            }
            textAlign="justify"
            fontFamily="Open Sans"
            my={6}
          >
            {domToReact(children, options)}
          </Text>
        );
      }
      if (name === "b") {
        return (
          <Heading fontSize="3xl" mt={4}>
            {domToReact(children, options)}
          </Heading>
        );
      }
      if (name === "h1") {
        return (
          <Heading fontSize={{ base: "2xl", lg: "4xl" }} mt={4}>
            {domToReact(children, options)}
          </Heading>
        );
      }
      if (name === "ol") {
        return <ol className="customOl">{domToReact(children, options)}</ol>;
      }
      if (name === "ul") {
        return <ol className="customUl">{domToReact(children, options)}</ol>;
      }
      if (attribs.class && attribs.class.includes("gallery")) {
        const split = attribs.id.split("-");
        return getImages(split[1]);
      }
      if (name === "img") {
        return (
          <Image
            src={attribs.src.replace("http:", "https:")}
            mx="auto"
            objectFit="contain"
          ></Image>
        );
      }
    },
  };
  return parse(props.content, options);
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
const Gallery = ({ images }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <MImage
          key={page}
          src={images[imageIndex].replace("http:", "https:")}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, info) => {
            const swipe = swipePower(info.offset.x, info.velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      <div className="next" onClick={() => paginate(1)}>
        <ChevronRightIcon />
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        <ChevronLeftIcon />
      </div>
    </>
  );
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const MImage = styled(motion.img)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
