import { Box, Flex, Image, Heading, Text, Link } from "@chakra-ui/react";
import rotaryLogo from "../../assets/rotary_international.svg";
import mainPlaceholder from "../../assets/placeholder_about.png";
import inspiration from "../../assets/inspiration.svg";
import children from "../../assets/children.png";

const MainAbout = () => {
  return (
    <>
      <Box bgColor="rBlue.700" pos="relative">
        <Box maxW="7xl" mx="auto">
          <Flex py={12} flexWrap={{ base: "wrap", lg: "nowrap" }}>
            <Flex
              mx={4}
              w={{ base: "100%", lg: 2 / 5 }}
              flexWrap={{ base: "wrap" }}
            >
              <Image w={3 / 5} src={rotaryLogo} mx="auto" />
              <Image
                d={{ base: "block", lg: "block" }}
                mt={10}
                w={4 / 5}
                objectFit="cover"
                mx="auto"
                src={mainPlaceholder}
                px={4}
              />
            </Flex>
            <Box
              mx={{ base: 0, lg: 4 }}
              px={{ base: 4, lg: 0 }}
              w={{ base: "100%", lg: 3 / 5 }}
              mt={{ base: 6, lg: 0 }}
            >
              <Box
                bgColor="white"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
                borderRadius="lg"
                p={4}
              >
                <Heading
                  as="h3"
                  fontSize="lg"
                  fontFamily="Open Sans"
                  color="rBlue.300"
                  textAlign="center"
                >
                  ŠTO JE ROTARY?
                </Heading>
                <Box maxW="xl" mx="auto">
                  <Text my={4} textAlign="justify">
                    Postoji mnogo definicija, no većini je zajedničko sljedeće:
                  </Text>
                  <Text my={4} textAlign="justify">
                    Rotary je međunarodno udruženje osoba iz stručnog i
                    poslovnog života koje se posvećuju humanim ciljevima, cijene
                    i promiču visoka etička načela u poslovnom i privatnom
                    životu, te šire dobru volju i razumijevanje u svijetu. Od
                    riječi “Rotary” treba razlikovati “Rotary International”
                    (RI), čime se misli na svjetsku organizacijsku strukturu
                    (klubove i distrikte u svim dijelovima svijeta, te centralu
                    u Evanstonu, IL, USA).
                  </Text>
                  <Text my={4} textAlign="justify">
                    Rotary International je prva svjetska “Service club”
                    organizacija koja trenutno ima više od 1.2 milijuna članova
                    u gotovo svim zemljama svijeta.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Između ostalog, RI je zbog svojih aktivnosti (kao što su
                    “PolioPlus”, “World Understanding and Peace”, itd.),
                    nevladina udruga međunarodnog značenja, priznata i od stane
                    UN-a.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Financiranje projekata i programa na razini Rotary
                    Internationala obavlja se kroz Rotary Foundation
                  </Text>
                  <Text my={4} textAlign="justify">
                    Rotary Foundation je neprofitna organizacija financirana
                    isključivo dobrovoljnim prilozima rotarijanaca i prijatelja
                    fundacije koji dijele viziju boljeg svijeta. Do sada su
                    Rotarijanci u Rotary Foundation donirali preko 2 milijarde
                    $.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Više informacija pročitajte
                    <span>
                      <Link
                        ml={2}
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.rotary.hr/webapp/f?p=1910:340:0::NO::P340_ID:5"
                        color="rBlue.300"
                      >
                        na ovom linku
                      </Link>
                    </span>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box bgColor="rGray.100">
        <Box maxW="7xl" mx="auto">
          <Flex py={12} mt={8} flexWrap={{ base: "wrap", lg: "nowrap" }}>
            <Box
              mx={{ base: 0, md: 4 }}
              px={{ base: 4, md: 0 }}
              w={{ base: "100%", lg: 3 / 5 }}
              mt={{ base: 8, lg: 0 }}
            >
              <Box
                bgColor="white"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
                borderRadius="lg"
                p={4}
              >
                <Heading
                  as="h3"
                  fontSize="lg"
                  fontFamily="Open Sans"
                  color="rBlue.300"
                  textAlign="center"
                >
                  ROTARY DISTRICT 1913
                </Heading>
                <Box maxW="xl" mx="auto">
                  <Text my={4} textAlign="justify">
                    Rotary District 1913 je dobrovoljna, samostalna i neprofitna
                    udruga Rotary klubova na području Republike Hrvatske, koji
                    su ujedno članovi Rotary Internationala.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Prvi klub osnovan u Hrvatskoj je Rotary klub Zagreb koji je
                    23. listopada 1990. uključen u svjetsku Rotary zajednicu. Od
                    tada do danas osnovano je 53 klubova širom Hrvatske i
                    okuplja 1293 rotarijanki i rotarijanaca.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Nakon više od 20 godina uspješnog djelovanja naših klubova u
                    okviru regionalnog Distrikta 1910, odlukom Rotary
                    Internationala formiran je samostalni Distrikt 1913 koji
                    podupire rad i razvoj klubova na području Hrvatske, prateći
                    potrebe lokalnih zajednica, a u skladu s Rotary načelima i
                    zakonskim regulativama.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Povelju Distrikta 1913 osobno je uručio predsjednik Rotary
                    Internationala Ray Klinginsmith prvom guverneru Distrikta
                    1913 dr. Ivi Husiću na svečanosti koja se održala u
                    Hrvatskom narodnom kazalištu u Zagrebu, 18. lipnja 2011.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Do sada su hrvatski Rotary klubovi ostvarili mnoge projekte
                    i programe te tako pomagali stanovnicima Hrvatske, većinom
                    iz vlastitih izvora ali i međunarodnom suradnjom drugih
                    Rotary klubova koji se rado odazivaju i materijalnim i
                    osobnim sudjelovanjem.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Osnivanjem Distrikta 1913 hrvatski rotarijanci prihvatili su
                    odgovornost i izazove ove velike i ugledne svjetske
                    organizacije u samostalnom iniciranju i pomaganju po
                    principima pravednog služenja zajednici.
                  </Text>
                  <Text my={4} textAlign="justify">
                    Više informacija pročitajte
                    <span>
                      <Link
                        ml={2}
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.rotary.hr/webapp/f?p=1910:340:0::NO::P340_ID:1"
                        color="rBlue.300"
                      >
                        na ovom linku
                      </Link>
                    </span>
                  </Text>
                  <Text my={4} textAlign="justify">
                    Rotary klub Bjelovar dio je Distrikta 1913
                  </Text>
                </Box>
              </Box>
            </Box>
            <Flex
              mx={4}
              order={{ base: -1, lg: 1 }}
              w={{ base: "100%", lg: 2 / 5 }}
              flexWrap={{ base: "wrap" }}
            >
              <Image w={3 / 5} src={inspiration} mx="auto" />
              <Image
                mt={10}
                w={{ base: "100%", lg: 4 / 5 }}
                objectFit="cover"
                mx="auto"
                src={children}
                px={4}
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MainAbout;
