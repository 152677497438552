import { Link } from "react-router-dom";
import { Box, Flex, Spacer, Button, Image } from "@chakra-ui/react";

const NewsCard = ({ data }) => {
  return (
    <Flex
      maxW="md"
      flexDir="column"
      w="100%"
      borderRadius="lg"
      overflow="hidden"
      m={{ base: 6, md: 0 }}
      mx={{ base: 6, md: 4 }}
      boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
    >
      <Image
        src={data.featured_img_tumbnail.replace("http:", "https:")}
        alt={`post ${data.title}`}
        objectFit="cover"
        w="100%"
        h="200px"
      />

      <Box mt="auto" mb={0} p="4" bgColor="white">
        <Box mt="1" as="h3" fontSize="lg" lineHeight="tight" minH="60px" fontWeight="semibold" color="rGray.600">
          {data.title}
        </Box>
        <Flex mt={2}>
          <Spacer />
          <Box>
            <Button
              variant="rotary"
              size="md"
              as={Link}
              to={`/vijest/${data.id}`}
            >
              Pročitaj više
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default NewsCard;

/* 
<Box
bgColor="rBlue.700"
boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
borderRadius="12px"
w={{ base: "100%", md: 1 / 2 }}
p={6}
mx={6}
>
<Flex>
  <Heading
    d="flex"
    pos="relative"
    fontSize={{ base: "xl", md: "2xl" }}
    mt={4}
    textAlign="center"
    color="white"
    mx="auto"
    _after={{
      pos: "absolute",
      content: "''",
      h: "3px",
      bottom: "-10px",
      left: data[0].title.length > 25 ? "10%" : "-10%",
      width: data[0].title.length > 25 ? "80%" : "120%",
      bgColor: "rBlue.300",
    }}
  >
    {data[0].title}
  </Heading>
</Flex>
<Box mt={8} maxW="sm" mx="auto">
  <Text color="white">
    <PostContent
      excerptFont={{ base: "md", lg: "lg" }}
      content={data[0].excerpt}
      id="0"
    />
  </Text>
  <Button
    w={1 / 2}
    as={Link}
    to={`/vijest/${data[0].id}`}
    d="flex"
    mt={4}
    ml="auto"
    mr="auto"
    variant="rotary"
  >
    Pročitaj više
  </Button>
</Box>
</Box> */
