import { Box, Flex, Image, Text } from "@chakra-ui/react";
import mark from "../../assets/favicon.svg";

const RightBox = ({ children }) => {
  return (
    <Flex w="100%">
      <Box
        d={{ base: "none", lg: "block" }}
        w={1 / 2}
        borderRight="2px solid #F8F9FA"
      ></Box>
      <Box
        w={{ base: "100%", lg: 1 / 2 }}
        borderLeft={{ base: "none", lg: "2px solid #F8F9FA" }}
      >
        <Box w={{ base: "100%", lg: 7.65 / 12 }} mt={8}>
          <Image
            ml={{ base: "auto" }}
            mr={{ base: "auto", lg: 0 }}
            w="50px"
            src={mark}
          />
        </Box>
        <Box
          h="3px"
          bgColor="rGray.100"
          mr="auto"
          w={{ base: "100%", lg: 3 / 5 }}
          mt={1}
        ></Box>
        <Text textAlign="justify" color="white" mt={4} ml={{ base: 0, lg: 4 }}>
          {children}
        </Text>
      </Box>
    </Flex>
  );
};

export default RightBox;
