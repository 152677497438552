import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { ScrollToTopOnMount } from "../helpers/hooks";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import razmjene from "../assets/razmjena.jpg";
import razmjene1 from "../assets/razmjene1.png";
import razmjene2 from "../assets/razmjene2.png";

const Exchange = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Box bgColor="rGray.100">
        <Box maxW="7xl" w="100%" mx="auto">
          <HeroTitle title="Razmjene" imgSrc={razmjene} />
          <Box>
            <Heading
              fontSize="2xl"
              textAlign="center"
              mt={20}
              mb={10}
              color="rBlue.700"
              px={{ base: 1, lg: 0 }}
            >
              Rotary razmjena mladih – Rotary Youth Exchange
            </Heading>
            <Text
              textAlign="justify"
              mx="auto"
              maxW="5xl"
              px={{ base: 6, lg: 0 }}
            >
              U programu rada kluba svake godine je i razmjena mladih (YE) –
              jedan od najvažnijih i najpoznatijih programa Rotary
              Internationala koji u svijetu daje mogućnost tisućama mladih ljudi
              upoznati ljude i kulture mnogih zemalja, koji na taj način siju
              doživotno sjeme međunarodnog razumijevanja i prijateljstva. U
              njemu sudjeluju učenici škola i fakulteta starosti od 12 do 25
              godina.
            </Text>
          </Box>
          <Box py={16} px={{ base: 5, lg: 0 }}>
            <Flex
              borderRadius="lg"
              boxShadow="lg"
              bgColor="white"
              overflow="hidden"
              flexWrap="wrap"
            >
              <Box
                p={{ base: 8, lg: 14 }}
                w={{ base: "100%", lg: 7 / 12 }}
                alignSelf="center"
                flexGrow={1}
              >
                <Text textAlign="justify">
                  U dosadašnjoj razmjeni Rotary kluba Bjelovar, u LONG TERM
                  boravku (cijelu školsku godinu) sudjelovalo je ukupno šesnaest
                  (16) učenika i to osam (8) naših učenika s jednogodišnjim
                  boravkom i pohađanjem srednje škole u Sjedinjenim američkim
                  državama (Zrinko, Marko, Mirta, Lana, Strahinja, Elvis, Dino i
                  Ivona), dok su bjelovarske srednje škole u isto vrijeme
                  pohađali (Tess, David, Katarina, Ruthie, Allyson i Ksenia) iz
                  Sjedinjenih američkih država. Henrique iz Brazila pohađao je
                  školu pola vremena u Garešnici, a pola u Zagrebu, dok je
                  Marcella pohađala školu pola vremena u Daruvaru, a pola u
                  Zagrebu.
                </Text>
              </Box>

              <Image w={{ base: "100%", lg: 5 / 12 }} src={razmjene1} />
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box bgColor="rBlue.700">
        <Box maxW="7xl" w="100%" mx="auto">
          <Flex flexWrap="wrap">
            <Box w={{ base: "100%", lg: 5 / 12 }}>
              <Image src={razmjene2} />
            </Box>
            <Box
              p={{ base: 8, lg: 14 }}
              w={{ base: "100%", lg: 7 / 12 }}
              alignSelf="center"
            >
              <Text textAlign="justify" color="white">
                U SHORT TERM razmjeni (dvotjedni ljetni kampovi diljem Europe i
                svijeta) uživali su i učili do sada 16-torica naših mladih:
                Zlatko (Švedska i Egipat), Ariana (Finska), Saša (Švicarska),
                Katarina i Jelena (Njemačka) te Katarina (Finska), Barbara
                (Švicarska), Sandra (Norveška), Ana (Turska), Marko (Švedska),
                Sven (Francuska), Vedrana (Njemačka), Ema (Engleska), Ema
                (Francuska), Eugen (Turska), Karmen (Finska), Luka (Nizozemska),
                sveukupno 17 kampova i učenika do sada u postojanju kluba.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box bgColor="rGray.100">
        <Box maxW="7xl" w="100%" mx="auto">
          <Flex justifyContent="center" py={20} px={{ base: 5, lg: 0 }}>
            <Box
              py={10}
              px={{ base: 8, lg: 20 }}
              layerStyle="card"
              bgColor="white"
            >
              <Heading
                textAlign="center"
                fontSize="3xl"
                color="rBlue.500"
                mb={12}
              >
                PRIJAVE ZA RAZMJENE
              </Heading>
              <Text fontSize="lg" textAlign="center" maxW="2xl" mb={2}>
                Prijave za{" "}
                <Box as="span" fontWeight="bold">
                  kratkoročnu razmjenu
                </Box>{" "}
                – kampovi 2017. i prijave za{" "}
                <Box as="span" fontWeight="bold">
                  dugoročnu razmjenu
                </Box>{" "}
                2017./2018. su otvorene.
              </Text>
              <Text fontSize="lg" textAlign="center" mb={16}>
                Detalje možete pogledati na donjem linku:
              </Text>
              <Button
                p={6}
                w="150px"
                variant="rotary"
                mx="auto"
                d="flex"
                as="a"
                href="https://www.rotary-zagreb.hr/webapp/f?p=1901:1"
                target="_blank"
                rel="noreferrer noopener"
              >
                PRIJAVI SE
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default Exchange;
