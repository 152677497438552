import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

const RegularMenu = () => {
  const [menu, setMenu] = useState({ first: false, second: false });

  return (
    <Flex
      as="nav"
      w="100%"
      maxW="3xl"
      mx="auto"
      d={{ base: "none", lg: "flex" }}
    >
      <Box
        d="flex"
        as="ul"
        mx="auto"
        w="100%"
        listStyleType="none"
        justifyContent="space-around"
      >
        <Box
          py={4}
          mr="5"
          onMouseOver={() => {
            setMenu((menu) => ({ ...menu, first: true }));
          }}
          onMouseLeave={() => {
            setMenu((menu) => ({ ...menu, first: false }));
          }}
        >
          <Menu isLazy isOpen={menu.first} placement="bottom">
            <MenuButton size="sm" layerStyle="menuItemBold">
              O nama {menu.first ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </MenuButton>
            <MenuList bgColor="rGray.100">
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem
                onClick={() => setMenu((menu) => ({ ...menu, first: false }))}
                as={Link}
                fontWeight="normal"
                to="/sastanci"
                layerStyle="menuItem"
              >
                Sastanci
              </MenuItem>
              <MenuItem
                onClick={() => setMenu((menu) => ({ ...menu, first: false }))}
                as={Link}
                fontWeight="normal"
                to="/vijesti"
                layerStyle="menuItem"
              >
                Vijesti
              </MenuItem>
              <MenuItem
                onClick={() => setMenu((menu) => ({ ...menu, first: false }))}
                as={Link}
                fontWeight="normal"
                to="/povijest"
                layerStyle="menuItem"
              >
                Povijest
              </MenuItem>
              <MenuItem
                onClick={() => setMenu((menu) => ({ ...menu, first: false }))}
                as={Link}
                fontWeight="normal"
                to="/kontakt"
                layerStyle="menuItem"
              >
                Kontakt
              </MenuItem>
              <MenuItem
                as={Link}
                onClick={() => setMenu((menu) => ({ ...menu, first: false }))}
                to="/uprava"
                layerStyle="menuItem"
              >
                Uprava
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box
          py={4}
          as={Link}
          to="/sto-je-rotary"
          mr="5"
          layerStyle="menuItemBold"
        >
          Što je rotary?
        </Box>
        <Box
          py={4}
          mr="5"
          layerStyle="menuItemBold"
          onMouseOver={() => {
            setMenu((menu) => ({ ...menu, second: true }));
          }}
          onMouseLeave={() => {
            setMenu((menu) => ({ ...menu, second: false }));
          }}
        >
          <Menu isLazy isOpen={menu.second} placement="bottom">
            <MenuButton size="sm" layerStyle="menuItemBold">
              Uključite se
              {menu.second ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </MenuButton>
            <MenuList bgColor="rGray.100">
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem
                as={Link}
                to="/projekti"
                onClick={() => setMenu((menu) => ({ ...menu, second: false }))}
                layerStyle="menuItem"
                fontWeight="normal"
              >
                Projekti
              </MenuItem>
              <MenuItem
                onClick={() => setMenu((menu) => ({ ...menu, second: false }))}
                as={Link}
                fontWeight="normal"
                to="/razmjene"
                layerStyle="menuItem"
              >
                Razmjene
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box py={4} as={Link} to="/galerije" mr="5" layerStyle="menuItemBold">
          Galerije
        </Box>
      </Box>
    </Flex>
  );
};

export default RegularMenu;
