import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HeroTitle = ({ imgSrc, title, bgPos, hasBreadcrumbs }) => {
  return (
    <Flex
      w="100%"
      background={`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${
        imgSrc ? imgSrc.replace("http:", "https:") : ""
      })`}
      bgColor="rBlue.700"
      backgroundPosition={bgPos ? bgPos : "center"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      h="300px"
      justifyContent="center"
    >
      <Box mt="auto" mb={16} px={14}>
        {hasBreadcrumbs && (
          <Breadcrumb textAlign="left" fontSize="lg">
            <BreadcrumbItem>
              <BreadcrumbLink color="white" as={Link} to="/galerije">
                Natrag na galerije
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <Heading
          fontFamily="Open Sans"
          fontSize={{ base: "3xl", lg: "5xl" }}
          color="white"
          textAlign="center"
        >
          {title}
        </Heading>
      </Box>
    </Flex>
  );
};

export default HeroTitle;
