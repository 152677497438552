import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body:
      "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    heading:
      "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  },
  layerStyles: {
    menuItemBold: {
      color: "rBlue.700",
      fontWeight: "600",
      fontFamily: "Open Sans",
    },
    menuItem: {
      color: "rBlue.700",
      fontFamily: "Open Sans",
    },
    card: {
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)",
      borderRadius: "lg",
    },
    rotaryAccordion: {
      _focus: {
        outline: "none",
        boxShadow: "none",
      },
      _active: {
        outline: "none",
        boxShadow: "none",
      },
    },
  },
  colors: {
    rBlue: {
      300: "#019FCB",
      500: "#0050A2",
      700: "#0C3C7C",
    },
    rRed: {
      300: "#C10042",
    },
    rGray: {
      100: "#F8F9FA",
      300: "#5E717D",
      600: "#263B4C",
    },
    rGreen: {
      500: "#018D8D",
    },
  },
  components: {
    Button: {
      variants: {
        rotary: {
          borderRadius: 0,
          bgColor: "rRed.300",
          color: "white",
          borderWidth: 1,
          borderColor: "rRed.300",
          transition: "all 0.2s ease-in",
          _hover: {
            bgColor: "white",
            color: "rRed.300",
            borderColor: "rRed.300",
            borderWidth: 1,
            transition: "all 0.2s ease-in",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
