import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Main from "../../pages/Main";
import WhatIsRotary from "../../pages/WhatIsRotary";
import Exchange from "../../pages/Exchange";
import Contact from "../../pages/Contact";
import Galleries from "../../pages/Galleries";
import Post from "../../pages/Post";
import GalleryPost from "../../pages/GalleryPost";
import NewsPost from "../../pages/NewsPost";
import Administration from "../../pages/Administration";
import Projects from "../../pages/Projects";
import SearchResult from "../../pages/SearchResult";
import History from "../../pages/History";
import Meetings from "../../pages/Meetings";
import MeetingPost from "../../pages/MeetingPost";
import News from "../../pages/News";

import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Box flex="1 0 auto">
          <Router>
            <Header />
            <Switch>
              <Route exact path="/">
                <Main />
              </Route>
              <Route exact path="/sto-je-rotary">
                <WhatIsRotary />
              </Route>
              <Route exact path="/razmjene">
                <Exchange />
              </Route>
              <Route exact path="/kontakt">
                <Contact />
              </Route>
              <Route exact path="/galerije">
                <Galleries />
              </Route>
              <Route exact path="/post/:id">
                <Post />
              </Route>
              <Route exact path="/galerija/:id">
                <GalleryPost />
              </Route>
              <Route exact path="/vijest/:id">
                <NewsPost />
              </Route>
              <Route exact path="/sastanak/:id">
                <MeetingPost />
              </Route>
              <Route exact path="/uprava">
                <Administration />
              </Route>
              <Route exact path="/projekti">
                <Projects />
              </Route>
              <Route exact path="/povijest">
                <History />
              </Route>
              <Route exact path="/pretraga/:query">
                <SearchResult />
              </Route>
              <Route exact path="/sastanci">
                <Meetings />
              </Route>
              <Route exact path="/vijesti">
                <News />
              </Route>
            </Switch>
          </Router>
        </Box>
        <Footer flexShrink="0" />
      </QueryClientProvider>
    </>
  );
}

export default App;
