import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import sastanci from "../assets/sastanci.jpg";
import mark from "../assets/favicon.svg";

const Meetings = () => {
  return (
    <Box w="100%" bgColor="rGray.100" pb={20}>
      <Box maxW="7xl" w="100%" mx="auto">
        <HeroTitle imgSrc={sastanci} title="Sastanci" bgPos="bottom" />
        <Flex mt={8} flexWrap="wrap">
          <Box
            w={{ base: "100%", lg: 1 / 2 }}
            px={{ base: 6, lg: 6 }}
            pl={{ base: 6, lg: 0 }}
          >
            <Flex
              borderRadius="lg"
              bgColor="rBlue.700"
              h="100%"
              p={6}
              flexWrap="wrap"
              alignContent="center"
              boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
            >
              <Image src={mark} alt="mark of excellence" mx="auto" />
              <Heading
                color="white"
                fontSize={{ base: "2xl", lg: "3xl" }}
                w="100%"
                mt={4}
                textAlign="justify"
              >
                Sastanci Rotary kluba Bjelovar održavaju se svakog utorka u
                20:00 sati u Hotelu Central, V. Lisinskog 2 u Bjelovaru.
              </Heading>
            </Flex>
          </Box>
          <Box
            w={{ base: "100%", lg: 1 / 2 }}
            px={{ base: 6, lg: 6 }}
            pr={{ base: 6, lg: 0 }}
            mt={{ base: 8, lg: 0 }}
          >
            <Box>
              <Box
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
                borderRadius="lg"
                as="iframe"
                title="google maps"
                w="100%"
                h="600px"
                frameBorder="0"
                allowFullScreen="allowfullscreen"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.7062627592777!2d16.841144915731988!3d45.89718757910846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4766360a2fce196d%3A0x222c635ad1821419!2sUl.+Vatroslava+Lisinskog+2%2C+43000%2C+Bjelovar!5e0!3m2!1sen!2shr!4v1477754018891"
              ></Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Meetings;
