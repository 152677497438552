import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Input,
  useDisclosure,
  FormErrorMessage,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const MobileSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef();
  const finalRef = useRef();

  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  function goto(values) {
    history.push(`/pretraga/${values.pretraga}`);
  }
  function submit(values, e) {
    goto(values);
    e.target.reset();
    onClose();
  }

  return (
    <Box>
      <IconButton
        onClick={onOpen}
        bgColor="transparent"
        _focus={{ boxShadow: "none" }}
        icon={<SearchIcon w="1.4rem" h="1.4rem" />}
      >
        open
      </IconButton>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader>Pretraživanje</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} as="form" onSubmit={handleSubmit(submit)}>
            <FormControl isInvalid={errors.pretraga && true}>
              <Input
                name="pretraga"
                ref={(e) => {
                  register(e, { required: "Obvezno polje" });
                  initialRef.current = e;
                }}
                placeholder="Pretraži"
                _placeholder={{ fontStyle: "italic" }}
              />
              <FormErrorMessage>
                {errors.pretraga && errors.pretraga.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MobileSearch;
