import {
  Box,
  Flex,
  Heading,
  UnorderedList,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";

const MainValues = () => {
  return (
    <Flex
      maxW="6xl"
      mx="auto"
      mt={12}
      mb={12}
      flexWrap={{ base: "wrap", md: "nowrap" }}
    >
      <Box
        bgColor="white"
        boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
        borderRadius="12px"
        w={{ base: "100%", md: 1 / 2 }}
        p={6}
        mx={6}
      >
        <Flex>
          <Heading
            d="flex"
            pos="relative"
            fontSize={{ base: "xl", md: "2xl" }}
            mt={4}
            textAlign="center"
            color="gray.700"
            mx="auto"
            _after={{
              pos: "absolute",
              content: "''",
              h: "3px",
              bottom: "-10px",
              left: "-10%",
              width: "120%",
              bgColor: "rBlue.300",
            }}
          >
            Naše vrijednosti:
          </Heading>
        </Flex>
        <Flex justifyContent="center">
          <UnorderedList mt={8} ml={0} listStyleType="none">
            <ListItem>Pomaganje i služenje</ListItem>
            <ListItem>Preuzimanje vodstva</ListItem>
            <ListItem>Prijateljstvo i druženje</ListItem>
            <ListItem>Osobni integritet i čestitost</ListItem>
            <ListItem>Poticanje različitosti i tolerancije</ListItem>
          </UnorderedList>
        </Flex>
      </Box>
      <Box
        bgColor="rBlue.700"
        color="white"
        boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
        borderRadius="12px"
        w={{ base: "100%", md: 1 / 2 }}
        p={6}
        mx={6}
        mt={{ base: 8, md: 0 }}
      >
        <Flex>
          <Heading
            d="flex"
            pos="relative"
            fontSize={{ base: "xl", md: "2xl" }}
            mt={4}
            textAlign="center"
            color="white"
            mx="auto"
            w="65%"
            _after={{
              pos: "absolute",
              content: "''",
              h: "3px",
              bottom: "-10px",
              left: "-10%",
              width: "120%",
              bgColor: "rBlue.300",
            }}
          >
            Što god govorimo ili činimo mora biti usklađeno sa sljedeća četiri
            pitanja:
          </Heading>
        </Flex>
        <Flex justifyContent="center">
          <OrderedList mt={8}>
            <ListItem>Je li to istinito?</ListItem>
            <ListItem>Je li pravedno za sve uključene?</ListItem>
            <ListItem>
              Pridonosi li izgradnji dobre volje i čvršćem prijateljstvu?
            </ListItem>
            <ListItem>Hoće li donijeti dobro svima uključenima?</ListItem>
          </OrderedList>
        </Flex>
      </Box>
    </Flex>
  );
};
export default MainValues;
