import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { handlePostRequest } from "../../helpers/requests";

const RotaryForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const toast = useToast();

  const submit = (values) => {
    setSubmitting(true);
    handlePostRequest("https://www.rzr.accountant.hr/utils/send-mail", [
      values,
    ]).then((res) => {
      if (res.data.response.accepted.length > 0) {
        setSubmitting(false);
        toast({
          title: "Poruka poslana.",
          description: "Vaša poruka uspješno je poslana.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reset();
      } else {
        toast({
          title: "Greška.",
          description: "Došlo je do pogreške, pokušajte ponovo.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      }
    });
  };
  return (
    <Box>
      <Box as="form" maxW="sm" mx="auto" onSubmit={handleSubmit(submit)}>
        <Box my={4}>
          <FormControl id="ime" isInvalid={errors.ime}>
            <FormLabel color="white">Ime</FormLabel>
            <Input
              name="ime"
              ref={register({ required: "Ime je obvezno polje" })}
              _placeholder={{ fontStyle: "italic" }}
              type="text"
              placeholder="Ime"
              bgColor="white"
            />
            <FormErrorMessage fontWeight="semibold">
              {errors.ime && errors.ime.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box my={4}>
          <FormControl id="E-mail" isInvalid={errors.email}>
            <FormLabel color="white">E-mail</FormLabel>
            <Input
              name="email"
              ref={register({
                required: "E-mail je obvezno polje",
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "E-mail nije ispravan",
                },
              })}
              _placeholder={{ fontStyle: "italic" }}
              placeholder="E-mail"
              type="text"
              bgColor="white"
            />
            <FormErrorMessage fontWeight="semibold">
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box my={4}>
          <FormControl id="nasov" isInvalid={errors.naslov}>
            <FormLabel color="white">Naslov</FormLabel>
            <Input
              name="naslov"
              ref={register({ required: "Naslov je obvezno polje" })}
              _placeholder={{ fontStyle: "italic" }}
              type="text"
              placeholder="Naslov"
              bgColor="white"
            />
            <FormErrorMessage fontWeight="semibold">
              {errors.naslov && errors.naslov.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box my={4}>
          <FormControl id="sadrzaj" isInvalid={errors.sadrzaj}>
            <FormLabel color="white">Sadržaj poruke</FormLabel>
            <Textarea
              name="sadrzaj"
              ref={register({ required: "Sadržaj poruke je obvezno polje" })}
              _placeholder={{ fontStyle: "italic" }}
              placeholder="Sadržaj poruke"
              bgColor="white"
              resize="none"
              rows="4"
            />
            <FormErrorMessage fontWeight="semibold">
              {errors.sadrzaj && errors.sadrzaj.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Button
          isLoading={isSubmitting}
          loadingText="Slanje"
          type="submit"
          variant="rotary"
          d="flex"
          mx="auto"
          w="200px"
          py={6}
        >
          POŠALJI
        </Button>
      </Box>
    </Box>
  );
};
export default RotaryForm;
