import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { handleGetRequest } from "../helpers/requests";
import {
  Box,
  Heading,
  Grid,
  Skeleton,
  IconButton,
  Text,
} from "@chakra-ui/react";
import Card from "../components/Main/Card.js";

const SearchResult = () => {
  let { query } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { data: res, isLoading } = useQuery(
    ["search", query],
    () =>
      handleGetRequest(
        `https://www.rotobj.parpar.hr/wp-json/wp/v2/vijest?search=${query}&per_page=12&page=${page}&_embed=1`
      ).then((res) => {
        const data = res.data.map((item) => {
          return {
            id: item.id,
            title: item.title.rendered,
            excerpt: item.excerpt.rendered,
            date: item.date,
            featured_img_full:
              item["_embedded"]["wp:featuredmedia"][0].media_details.sizes.full
                .source_url,
            featured_img_tumbnail:
              item["_embedded"]["wp:featuredmedia"][0].media_details.sizes
                .medium.source_url,
          };
        });
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        return { data: data, query };
      }),

    { enabled: query ? true : false }
  );

  return (
    <Box>
      <Box
        exit="exit"
        initial="exit"
        animate="enter"
        maxW="7xl"
        mx="auto"
        mt="3rem"
      >
        <Heading as="h5" size="lg" px={{ base: "1em", md: "0.5em" }}>
          Tražili ste:{" "}
          <Box as="span" textDecor="underline">
            {query}
          </Box>
        </Heading>
        <Box maxW="7xl" w="100%" mx="auto" mt={4}>
          {res && res.data.length < 1 && (
            <Text fontSize="4xl" mx={{ base: 4, lg: 0 }} fontWeight={500}>
              Nema rezultata pretrage
            </Text>
          )}
        </Box>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
          gap={6}
          maxWidth="7xl"
          width="100%"
          mx="auto"
          mt="2em"
          px={{ base: "2em", md: "1em" }}
        >
          {res && !isLoading
            ? res.data.map((item) => {
                return <Card key={item.id} location="vijest" btnSize="sm" data={item} />;
              })
            : [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                return (
                  <Skeleton
                    key={item}
                    width="100%"
                    height="250px"
                    borderRadius="25px"
                  ></Skeleton>
                );
              })}
        </Grid>
        <Box maxW="900px" mx="auto">
          {page < totalPages ? (
            <IconButton
              backgroundColor="gray.400"
              w="55px"
              h={30}
              color="white"
              ml="4em"
              borderRadius={25}
              _hover={{
                backgroundColor: "gray.500",
                transform: "scale(1.1)",
                transition: 0.3,
              }}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Sljedeća
            </IconButton>
          ) : null}
          {page > 1 && page <= totalPages ? (
            <IconButton
              backgroundColor="gray.400"
              w="55px"
              ml="4em"
              color="white"
              borderRadius={25}
              h={30}
              _hover={{
                backgroundColor: "gray.500",
                transform: "scale(1.1)",
                transition: 0.3,
              }}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Natrag
            </IconButton>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchResult;
