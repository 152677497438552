import { Box } from "@chakra-ui/react";
import MainHeading from "../components/Main/MainHeading";
//import MainPosts from "../components/Main/MainPosts";
import MainValues from "../components/Main/MainValues";
import MainAbout from "../components/Main/MainAbout";

const Main = () => {
  return (
    <Box w="100%" bgColor="rGray.100">
      <MainHeading />
     {/*  <MainPosts /> */}
      <MainValues />
      <MainAbout />
    </Box>
  );
};

export default Main;
