import { Box, Flex, Text, Image } from "@chakra-ui/react";
import mark from "../../assets/favicon.svg";

const LeftBox = ({ children }) => {
  return (
    <Flex w="100%">
      <Box
        w={{ base: "100%", lg: 1 / 2 }}
        borderRight={{ base: "none", lg: "2px solid #F8F9FA" }}
      >
        <Box
          ml={{ base: 0, lg: "auto" }}
          w={{ base: "100%", lg: 7.65 / 12 }}
          mt={8}
        >
          <Image w="50px" mx={{ base: "auto", lg: 0 }} src={mark} />
        </Box>

        <Box
          mt={1}
          h="3px"
          bgColor="rGray.100"
          ml="auto"
          w={{ base: "100%", lg: 3 / 5 }}
        ></Box>
        <Text textAlign="justify" color="white" mt={4} mr={{ base: 0, lg: 4 }}>
          {children}
        </Text>
      </Box>
      <Box
        d={{ base: "none", lg: "block" }}
        w={1 / 2}
        borderLeft="2px solid #F8F9FA"
      ></Box>
    </Flex>
  );
};

export default LeftBox;
