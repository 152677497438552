import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import mark from "../../assets/favicon.svg";

const ProjectCard = ({ data }) => {
  return (
    <Box p={4} w={{ base: "100%", lg: 1 / 2 }} flex="1 0 auto">
      <Flex
        px={4}
        py={6}
        bgColor="white"
        borderRadius="12px"
        boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
        h="100%"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box mx="auto" w="100%">
          <Image w="55px" mx="auto" src={mark} />
        </Box>
        <Heading
          mt={2}
          as="h2"
          fontSize="md"
          color="rGray.600"
          fontWeight="normal"
          textAlign="justify"
        >
          {data.title}
        </Heading>
      </Flex>
    </Box>
  );
};

export default ProjectCard;
