import { Box, ListItem, Flex, Text, UnorderedList } from "@chakra-ui/react";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import povijest from "../assets/povijest.jpg";
import LeftBox from "../components/History/LeftBox";
import RightBox from "../components/History/RightBox";

const History = () => {
  return (
    <>
      <Box w="100%" bgColor="rGray.100" pb={14}>
        <Box maxW="7xl" w="100%" mx="auto">
          <HeroTitle title="Povijest" imgSrc={povijest} />
          <Box mt={10} px={{ base: 4, lg: 0 }}>
            <Box
              bgColor="white"
              p={6}
              borderRadius="12px"
              boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
            >
              <Text my={4}>
                Prvi inicijalni razgovor na temu osnivanja Rotary kluba Bjelovar
                dogodio se jedne siječanjske nedjelje 2000. godine između dr.
                sc. Borisa Kirina, dr. med i prof. dr. sc. Hrvoja Vrčića,
                predsjednika Rotary kluba Zagreb.
              </Text>
              <Text my={4}>
                Istog dana dr. sc. Boris Kirin, dr. med. obavio je razgovor s
                prijateljima Željkom Maletićem, dipl. ing., Marijanom Gezijem,
                dipl. ing., Petrom Smiljanićem, dipl. iur., Zlatkom Gregurićem,
                dipl. iur. i Milanom Presečanom Arvayem, dipl. ing.
              </Text>
              <Text my={4}>
                Članovi osnivačke momčadi sastali su se na inicijativu prof. dr.
                sc. Hrvoja Vrčića, predsjednika Rotary kluba Zagreb 16. ožujka
                2000. godine u Petrovoj bolnici u Zagrebu. Sastanku su nazočili,
                uz predsjednika Rotary kluba Zagreb, dr. sc. Boris Kirin, dr.
                med, Zlatko Gregurić, dipl. iur. i Milan Presečan Arvay, dipl.
                ing.
              </Text>
              <Text my={4}>
                Nedugo nakon toga, 01. travnja 2000. godine, održan je prvi
                sastanak Rotary kluba Bjelovar u osnivanju na kojem su nazočili
                dr. sc. Boris Kirin, dr. med., Zlatko Gregurić, dipl. iur.,
                Milan Presečan Arvay, dipl. ing., Marijan Gezi, dipl. ing.,
                Željko Maletić, dipl. ing., Petar Smiljanić, dipl. iur., i Petar
                Radaković, dipl. oecc., Miroslav Čačija, dipl. iur., Zvonimir
                Iveković, nastavnik TZK, dr. sc. Dubravko Habek, dr. med i
                Berislav Schejbal, dipl. arheolog.
              </Text>
              <Text my={4}>
                18. travnja 2000. godine Rotary klub Bjelovar u osnivanju, koji
                je tada brojao 15 članova održao je osnivačku skupštinu kojoj je
                uz članove Rotary kluba Bjelovar prisustvovao i kum Rotary kluba
                Bjelovar prof. dr. sc. Hrvoje Vrčić, predsjednik Rotary kluba
                Zagreb.
              </Text>
              <Text my={4}>
                Rotary klub Bjelovar u osnivanju tada je dobio svoje rukovodstvo
                i nosioce aktivnosti. Na prijedloge prisutnih jednoglasno su
                izabrani članovi predsjedništva:
              </Text>
              <UnorderedList>
                <ListItem>Predsjednik - dr. sc. Boris Kirin, dr. med.</ListItem>
                <ListItem>Potpredsjednik - Željko Maletić, dipl. ing.</ListItem>
                <ListItem>
                  Predsjednik Elekt - Zlatko Gregurić, dipl. iur
                </ListItem>
                <ListItem>Tajnik - Milan Presečan Arvay, dipl. ing.</ListItem>
                <ListItem>Rizničar - Petar Radaković, dipl. oecc.</ListItem>
                <ListItem>
                  Stoloravnatelj - dr. sc. Dubravko Habek, dr. med.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </Box>
        <Box bgColor="rBlue.700" mt={10} py={10}>
          <Flex maxW="7xl" w="100%" mx="auto" flexWrap="wrap" px={8}>
            <LeftBox>
              2014. godine, odlukom guvernera Districta 1913, desetorici članova
              kluba s najdužim stažem služenja rotarijanskoj ideji dodijeljena
              je Paul Harris Fellow nagrada, i to pobrojano abecednim redom:
              Milan Arvay, dipl. ing, Dejan Đerić, dipl. iur., Mihael Bajić,
              Marijan Gezi, dipl. ing., Zlatko Gregurić, dipl. iur., Stjepan
              Horvat, dr. sc. Boris Kirin, dr. med., prim. Mislav Klobučić, dr.
              med., Željko Maletić, dipl. ing, Dejan Posavac, dipl. ing, i Petar
              Smiljanić, dipl. iur.
            </LeftBox>
            <RightBox>
              2013. godine Rotary klubu Bjelovar za značajan doprinos na
              području humanitarnog rada dodijeljen je Pečat grada Bjelovara.
            </RightBox>
            <LeftBox>
              18. lipnja 2011. Rotary klub Bjelovar, kao i svi Rotary klubovi u
              Hrvatskoj, njih ukupno 53, prelazi iz Rotary Districta 1910 u
              hrvatski Rotary District 1913. Prvi guverner Rotary districta 1913
              bio je dr. Ivo Husić.
            </LeftBox>
            <RightBox>
              2005. godine predstavnici Rotary kluba Bjelovar prisustvovali su
              svečanosti proslave 100 godina rotarijanstva u svijetu na
              svečanosti koja je upriličena u Bečkoj vijećnici.
            </RightBox>
            <LeftBox>
              2004. godine održana je Charter ceremonija Rotary kluba Koprivnica
              gdje je Rotary klub Bjelovar bio kumski klub, a kum Stjepan
              Kusturin, dipl. ing.
            </LeftBox>
            <RightBox>
              Prvi predsjednik Rotary kluba Bjelovar bio je dr. sc. Boris Kirin,
              dr.med., a klub je imao ukupno 25 članova.
            </RightBox>
            <LeftBox>
              Charter ceremonija Rotary kluba Bjelovar u okviru Districta 1910
              Rotary Internationala, održana je 7. travnja 2001. godine u
              nazočnosti predstavnika Rotary Districta 1910, Ratka Žurića, kao i
              predstavnika Rotary kluba Zagreb Hrvoja Vrčića, kuma Rotary kluba
              Bjelovar.
            </LeftBox>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default History;
