import axios from "axios";

const instance = axios.create();

export function handlePostRequest(url, options) {
  //console.log(url, options, token);

  return instance.post(url, ...options, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
}
export function handleGetRequest(url) {
  return instance.get(url);
}
export function handleGetPreview(url, options) {
  return instance.get(url, {
    withCredentials: true,
    /*  headers: {
      "X-WP-nonce": options.wpNonce,
    }, */
  });
}
