import {
  Box,
  Flex,
  Spacer,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
} from "@chakra-ui/react";
import MobileSearch from "./MobileSearch";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useRef } from "react";

const MobileMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Flex py={4} d={{ base: "flex", lg: "none" }} w="100%" px={4}>
      <Box>
        <IconButton
          ref={btnRef}
          onClick={onOpen}
          bgColor="transparent"
          _focus={{ boxShadow: "none" }}
          icon={<HamburgerIcon w="1.9rem" h="1.9rem" />}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader></DrawerHeader>

              <DrawerBody px={0} mt={4}>
                <Box>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <h2>
                        <AccordionButton layerStyle="rotaryAccordion">
                          <Box flex="1" textAlign="left">
                            O nama
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={0}>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/sastanci"
                                textAlign="left"
                                ml={4}
                              >
                                Sastanci
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/vijesti"
                                textAlign="left"
                                ml={4}
                              >
                                Vijesti
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/povijest"
                                textAlign="left"
                                ml={4}
                              >
                                Povijest
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/kontakt"
                                textAlign="left"
                                ml={4}
                              >
                                Kontakt
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>

                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/uprava"
                                textAlign="left"
                                ml={4}
                              >
                                Uprava
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton layerStyle="rotaryAccordion">
                          <Box
                            flex="1"
                            as={Link}
                            onClick={onClose}
                            to="/sto-je-rotary"
                            textAlign="left"
                          >
                            Što je rotary
                          </Box>
                        </AccordionButton>
                      </h2>
                    </AccordionItem>
                    <AccordionItem>
                      <h2>
                        <AccordionButton layerStyle="rotaryAccordion">
                          <Box flex="1" textAlign="left">
                            Uključite se
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={0}>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/projekti"
                                textAlign="left"
                                ml={4}
                              >
                                Projekti
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton layerStyle="rotaryAccordion">
                              <Box
                                flex="1"
                                as={Link}
                                onClick={onClose}
                                to="/razmjene"
                                textAlign="left"
                                ml={4}
                              >
                                Razmjene
                              </Box>
                            </AccordionButton>
                          </h2>
                        </AccordionItem>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <h2>
                        <AccordionButton layerStyle="rotaryAccordion">
                          <Box
                            flex="1"
                            as={Link}
                            onClick={onClose}
                            to="/galerije"
                            textAlign="left"
                          >
                            Galerije
                          </Box>
                        </AccordionButton>
                      </h2>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </DrawerBody>

              <DrawerFooter></DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
      <Spacer />
      <MobileSearch />
    </Flex>
  );
};
export default MobileMenu;
