import { Box, Text, Heading, Flex } from "@chakra-ui/react";
import { ScrollToTopOnMount } from "../helpers/hooks";
import WICard from "../components/WIRotary/WICard";
import HeroTitle from "../components/HeroTitle/HeroTitle";
import stojerotary from "../assets/sto_je_rotary_img.jpg";
import wir1 from "../assets/wir1.png";
import wir2 from "../assets/wir2.png";
import wir3 from "../assets/wir3.png";
import wir4 from "../assets/wir4.png";

const WhatIsRotary = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <Box w="100%" bgColor="rGray.100" mb={14}>
        <Box maxW="7xl" mx="auto">
          <HeroTitle title="Što je Rotary?" imgSrc={stojerotary} />
          <Box mt={{ base: 10, lg: 20 }} pb={20} px={{ base: 6, xl: 0 }}>
            <Box
              bgColor="white"
              p={8}
              pt={{ base: 4, lg: 8 }}
              borderRadius="lg"
              boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
            >
              <Box px={{ base: 0, lg: 20 }}>
                <Text textAlign="justify" my={4}>
                  “Rotarijanci su mini model jednog miroljubivog svijeta, koji
                  bi kao primjer mogao služiti svim narodima. Rotarijanci
                  vjeruju da je moguće ostvariti ono za čim svaki čovjek čezne,
                  a to su tolerancija, prijateljstvo i svjetski mir”.
                </Text>
                <Text textAlign="justify" my={4}>
                  Ovim riječima opisao je Paul P. Harris, utemeljitelj
                  rotarijanaca, svrhu i djelovanje velike rotarijanske obitelji.
                </Text>
                <Text textAlign="justify" my={4}>
                  Temeljene životne odrednice rotarijanaca sažete su u Statutu
                  koji ističe da je svrha rotarijanskog pokreta “spremnost
                  pomagati se u svakodnevnom životu”, a ostvaruje se
                  “njegovanjem prijateljstva i služenjem drugima”. Jednom
                  riječju, rotarijanci promiču najviše i najvrednije ljudske i
                  humanističke vrijednosti: toleranciju, mir i prijateljstvo!
                  Rotarijanci nastoje unijeti red tamo gdje vlada kaos,
                  harmoniju u disharmoniju, prijateljstvo i ljubav u mržnju i
                  nesnošljivost, puninu života u samoću i siromaštvo, zdravlje i
                  sreću tamo gdje postoji nesreća i bolest, a u mraku pale
                  svijeću. Rotarijanci nastoje da ljudi svih boja i rasa, raznih
                  religija i vjeroispovijesti, političkih opredjeljenja i
                  svjetonazora, postanu velika i sretna obitelj, za čiju se
                  dobrobit isplati raditi i žrtvovati. Čovjek i njegovo dobro u
                  središtu su razmišljanja i djelovanja rotarijanaca.
                </Text>
                <Text textAlign="justify" my={4}>
                  Rotarijanski pokret rođen je hladnog zimskog dana, 23. veljače
                  1905. kada se Paul Harris, tadašnji mladi odvjetnik, sastao u
                  Chicagu s trojicom svojih prijatelja: Silvesterom Schieleom,
                  trgovcem ugljenom, Gustavom E. Loehrom, rudarskim inženjerom i
                  Hiramom Shoreyom, krojačem, i utemeljio udrugu poslovnih ljudi
                  povezanih prijateljstvom i spremnošću da si pomažu u struci.
                </Text>
                <Text textAlign="justify" my={4}>
                  Sastanci su se održavali svaki put kod nekog drugog. Počeli su
                  dakle rotirati, od čega je nastao naziv Rotary klub. Načelo
                  rotiranja imalo je za svrhu da se članovi što više upoznaju sa
                  sredinama u kojima žive i djeluju pojedini članovi. Četvorica
                  utemeljitelja bili su američkog, njemačkog, švedskog i irskog
                  podrijetla, a pripadali su protestantskoj, rimokatoličkoj i
                  židovskoj vjeroispovijesti. Upravo zato simbol Rotary klubova
                  predstavlja kotač u pokretu koji simbolizira civilizaciju i
                  neposredno kretanje.
                </Text>
                <Text textAlign="justify" my={4}>
                  Prvi “Rotary Club” Chicago imao je 30 članova. Nova udruga
                  našla je oduševljene pristaše u Americi i u Europi, a zatim i
                  u svim ostalim dijelovima svijeta. Prvi kongres rotarijanaca
                  održan je 1910. godine u Chicagu u nazočnosti predstavnika 16
                  klubova. Ideja služenja izražena u geslu “He profits Most Who
                  Serves Best” – “Tko služi drugom, najviše koristi sebi” i
                  “Service Above Self” – “Služiti bez osobne koristi” postala je
                  službenim geslom rotarijanaca i potvrđena je na kongresu u
                  Detroitu 1950. godine. Prvi časopis “The Rotarian”, koji danas
                  povezuje sve rotarijance svijeta, počeo je izlaziti 1911.
                  godine. Tijekom svoje povijesti Rotary je bio svugdje gdje je
                  trebalo pomoći ljudima.
                </Text>
                <Text textAlign="justify" my={4}>
                  U želji da stvore bolji svijet, rotarijanci su potaknuli
                  pedagoške i karitativne projekte i međunarodnu razmjenu. Tako
                  su nastale Rotary zaklade, među kojima se i stiče ona koja
                  omogućuje razmjenu studenata i njihovo stipendiranje u
                  inozemstvu. Ta zaklada dobila je na važnosti poslije 1928.
                  godine, osamdesetih je godina nazvana „Paul Harris Fellow“, a
                  omogućila je da se 30.000 mladih kvalificiranih žena i
                  muškaraca, kao stipendisti rotarijanaca i kao „ambasadori
                  dobre volje“ u 130 zemalja svijeta usavršavaju u svojoj struci
                  i svojim zvanjima. Do sada je ta zaklada zbrinula više od
                  150.000 studenata.
                </Text>
                <Text textAlign="justify" my={4}>
                  U mnogobrojnim svjetskim programima Rotary Internationala
                  ističe se program „3H Programm“ (Health, Hunger and Humanity),
                  kojim se nastoji ublažiti glad, poboljšati zdravstvene prilike
                  i unaprijediti socijalni boljitak u svijetu. Uz njega se
                  ističe i program „Polio Plus“ kojem je cilj da se suradnjom sa
                  sličnim državnim i međunarodnim zdravstvenim organizacijama do
                  2005. godine, odnosno do stote obljetnice osnivanja Rotaryja,
                  iskorijeni dječja paraliza u svijetu. Do sada je uz novčanu
                  potporu rotarijanaca više od pola milijarde djece u zemljama u
                  razvoju primilo cjepivo protiv dječje paralize.Danas Rotary
                  International pripada jednoj od 42 nevladine humanitarne
                  organizacije priznate od UN-a. Današnji osnovni humanitarni
                  program rotarijanaca za
                </Text>
                <Text textAlign="justify" my={4}>
                  suzbijanje gladi, bolesti i pružanja pomoći iznosi godišnje
                  više od 500 milijuna USD.
                </Text>
                <Text textAlign="justify" my={4}>
                  Od samih početaka Rotarijanci su uvijek bili predani promidžbi
                  visokih etičkih načela u poslovnome životu. Najjednostavnija
                  je takva provjera poslovne etike znameniti Test četiri
                  pitanja. Prihvaćen je od međunarodnog Rotaryja 1943. godine, a
                  preveden je više stotina jezika i objavljen u tisućama oblika.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box bgColor="rBlue.700" pb={12}>
          <Box maxW="7xl" w="100%" mx="auto">
            <Flex mx="auto" justifyContent="center">
              <Heading
                d="flex"
                pos="relative"
                textAlign="center"
                my={8}
                mt={14}
                color="white"
                fontFamily="Open Sans"
                fontSize={{ base: "xl", md: "2xl" }}
                _after={{
                  pos: "absolute",
                  content: "''",
                  h: "3px",
                  bottom: "-6px",
                  left: { base: "20%", lg: "0" },
                  width: { base: "60%", lg: "38%" },
                  bgColor: "rBlue.300",
                }}
              >
                Što god govorimo ili činimo mora biti usklađeno sa sljedeća
                četiri pitanja:
              </Heading>
            </Flex>
            <Flex maxW="2xl" mx="auto" w="100%" flexWrap="wrap">
              <WICard imgSrc={wir1} title="1. Je li istinito" />
              <WICard
                imgSrc={wir2}
                title="2. Je li pravedno za sve uključene?"
              />
              <WICard
                imgSrc={wir3}
                title="3. Pridonosi li izgradnji dobre volje i čvršćem prijateljstvu?"
              />
              <WICard
                imgSrc={wir4}
                title="4. Hoće li donijeti dobro svim uključenima?"
              />
            </Flex>
          </Box>
        </Box>
        <Box h="100%" bgColor="rGray.100"></Box>
      </Box>
    </>
  );
};

export default WhatIsRotary;
