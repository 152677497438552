import { Box, Button, Image, Flex, Spacer, forwardRef } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { motion, isValidMotionProp } from "framer-motion";
import { formatPostDate } from "../../helpers/functions";

const MotionBox = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref} {...chakraProps} />;
  })
);
const variants = {
  enter: (direction) => {
    return {
      scale: 0.8,
    };
  },
  center: {
    zIndex: 1,
    opacity: 1,
    scale: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      opacity: 0,
      scale: 0.8,
    };
  },
};

const Card = ({ data, custom, location }) => {
  return data ? (
    <MotionBox
      maxW="xs"
      w="100%"
      key={data}
      custom={custom}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 70 },
        opacity: { duration: 0.3 },
      }}
      borderRadius="lg"
      overflow="hidden"
      mx="auto"
      boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
    >
      <Image
        src={data.featured_img_tumbnail.replace("http:", "https:")}
        alt={`post ${data.title}`}
        objectFit="cover"
        w="100%"
        h="200px"
      />

      <Box p="4" bgColor="white">
        <Box mt="1" as="h4" lineHeight="tight">
          {data.title}
        </Box>
        <Flex mt={2}>
          <Box color="rBlue.700">{formatPostDate(data.date)}</Box>
          <Spacer />
          <Box>
            <Button
              as={Link}
              to={`/${location ? location : "post"}/${data.id}`}
              variant="rotary"
              size="sm"
            >
              Pročitaj više
            </Button>
          </Box>
        </Flex>
      </Box>
    </MotionBox>
  ) : null;
};

export default Card;
